@font-face {
  font-family: 'MinecraftiaRegular';
  src: url('/public/font/MinecraftiaRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container {
  width: 95% !important;
  max-width: 1024px !important;
}

.masthead-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.masthead-title h1 {
  color: #e5e9f0;
  mix-blend-mode: overlay;
  font-family: 'MinecraftiaRegular';
  font-size: 4rem;
}

.masthead-title img {
  width: 8rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 650px) {
  .masthead-title {
    display: block;
  }

  .masthead-title h1 {
    color: #e5e9f0;
    mix-blend-mode: overlay;
    font-family: 'MinecraftiaRegular';
    font-size: 2rem;
  }

  .masthead-title img {
    width: 3rem;
  }

  a.nav-link[data-rr-ui-event-key='Dashboard'] {
    display: none;
  }
}

.masthead {
  /* background-image: url('/public/img/Pano1-Blurred.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-attachment: fixed; */
  height: auto;
}

.masthead h6 {
  color: #e5e9f0;
  mix-blend-mode: difference;
  font-family: 'MinecraftiaRegular';
  margin-bottom: 2rem;
}

.mc_title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mc_title img {
  width: 48px;
  height: 48px;
  user-select: none;
}

.masthead .nav {
  margin: auto;
  width: max-content;
  color: #e5e9f0;
  font-family: 'MinecraftiaRegular';
}

.nav-link {
  cursor: pointer !important;
}

.nav-link:not(.active) {
  cursor: pointer !important;
  width: max-content;
  color: #e5e9f0;
  transition: color ease-in-out 500ms;
}

.nav-link:not(.active):hover,
.nav-link:not(.active):focus {
  cursor: pointer !important;
  color: #8fbcbb;
  mix-blend-mode: overlay;
  transition: color ease-in-out 500ms;
}

.nav-link.active {
  cursor: pointer !important;
  background-color: #8fbcbb !important;
  color: #e5e9f0 !important;
}

body {
  color: #e5e9f0 !important;
  /* background-color: #2e3440 !important; */
  background-image: url('/public/img/Pano1-Blurred.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-attachment: fixed;
}

.forgeserv-active .container {
  background-color: #2e3440c0;
  border-radius: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.server-card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.25rem !important;
}

.server-title {
  font-family: 'MinecraftiaRegular';
  font-size: 28px;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}

.server-icon {
  width: 64px;
  height: 64px;
  margin-right: 1rem;
}

.error-text {
  width: 100%;
  height: 100%;
  border: 2px solid #bf616a;
  border-radius: 8px;
}

a.dynmap {
  text-decoration: none;
  margin-left: 0.5rem;
}

a.dynmap svg {
  margin-bottom: 12px;
  color: #2e3440;
}

.dynmap-popover {
  font-family: var(--bs-body-font-family) !important;
  padding: 0.25rem 0.5rem;
  color: #2e3440;
}
